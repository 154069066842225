import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const classes = {
	linkStyle: {
        textDecoration: 'none',
		color: '#2c88d9',
	},
    linksBox: {
        margin: 2
    },
    copyRightBox: {
        margin: 2,
        textAlign: 'right'
    }
};


function Footer() {
    return (
        <Grid container>
            <Grid item xs={12} sm={6}>
                <Box sx={classes.linksBox}>
                    <Link style={classes.linkStyle}>Warehouse Locations </Link>|
                    <Link style={classes.linkStyle}> Customer Service </Link>|
                    <Link style={classes.linkStyle}> Privacy Policy </Link>|
                    <Link style={classes.linkStyle}> Blog </Link>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Box sx={classes.copyRightBox}>
                    <Typography>© Copyright 2024, Direct Freight Quotes Corp. All rights Reserved</Typography>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Footer;
import { quoteConstants } from '../_constants';

const initialState = {
    loading: false,
    quotes: [],
    winningQuote: null,
    quoteInfoForPdf: null
};

export function quote(state = initialState, action) {
    switch(action.type) {
        case quoteConstants.POST_LOAD_FOR_QUOTING_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.POST_LOAD_FOR_QUOTING_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.POST_LOAD_FOR_QUOTING_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.POST_LOAD_FOR_INDIVIDUAL_QUOTING_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.POST_LOAD_FOR_INDIVIDUAL_QUOTING_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.POST_LOAD_FOR_INDIVIDUAL_QUOTING_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.GET_QUOTES_REQUEST:
            return {
                ...state,
                loading: true,
                quotes: []
            };
        case quoteConstants.GET_QUOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                quotes: action.quotes
            };
        case quoteConstants.GET_QUOTES_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.GET_WINNING_QUOTE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.GET_WINNING_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false,
                winningQuote: action.winningQuote
            };
        case quoteConstants.GET_WINNING_QUOTE_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.AWARD_QUOTE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.AWARD_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.AWARD_QUOTE_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.REVOKE_WINNING_QUOTE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.REVOKE_WINNING_QUOTE_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.REVOKE_WINNING_QUOTE_FAILURE:
            return {
                ...state,
                loading: false
            };
        case quoteConstants.GET_QUOTE_INFO_FOR_PDF_REQUEST:
            return {
                ...state,
                loading: true
            };
        case quoteConstants.GET_QUOTE_INFO_FOR_PDF_SUCCESS:
            return {
                ...state,
                loading: false,
                quoteInfoForPdf: action.quoteInfoForPdf
            };
        case quoteConstants.GET_QUOTE_INFO_FOR_PDF_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadActions } from '../../_actions';
import { useFormContext, Controller  } from "react-hook-form";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FormControl, InputAdornment } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const classes = {
	formInput: {
        width: '100%',
    },
    formSection: {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        marginBottom: 2
    },
    formSubHeaders: {
        paddingBottom: 1,
        fontSize: 20
    },
    selectInput: {
		width: '100%',
		marginTop: 2,
	},
    radioGroupStyle: {
        border: '1px solid grey',
        paddingLeft: 10
    },
    toolTipStyle: {
        color: '#2c88d9',
    }
};

const palletDimensionOptions = ['40"x48"', '48"x20"', '24"x40"', 'custom'];

function LoadInfoForm(props){
    const [customPalletDimensions, setCustomPalletDimensions] = useState(false);
    const { loadTemplate, loadTemplateDetails } = props;
    const loadingTemplate = useSelector(state => state.load.loadingTemplate);
    const dispatch = useDispatch();

    const { control, register, reset, formState: { errors, submitCount }, setValue } = useFormContext();
	// HAVING YUP VALIDATIONSCHEMA THATS NOT BEING USED WILL CAUSE THE FORM TO NOT SUBMIT

    useEffect(() => { 
        if(loadTemplateDetails){
            palletDimensionOptions.includes(loadTemplateDetails.PalletDimensions) ? setCustomPalletDimensions(false) : setCustomPalletDimensions(true);
        }
		reset(loadTemplateDetails);
    }, [loadTemplateDetails]);

    useEffect(() => { 
		if(loadTemplate){
			reset();
			dispatch(loadActions.getLoadTemplateDetails(loadTemplate.Id));
		}
    }, [loadTemplate]);

    const IconWithTooltip = () => (
        <Tooltip arrow title="Click to view pallet dimension options" placement="top" sx={classes.toolTipStyle}>
            <IconButton onClick={resetCustomPalletDimensions}>
                <InfoIcon />
            </IconButton>
        </Tooltip>
    );

    const resetCustomPalletDimensions = () => {
        setCustomPalletDimensions(false);
        setValue('palletDimensions', '');
    };

    return (
        <>
            <Typography sx={classes.formSubHeaders}>Load / Shipping Information</Typography>
            {!loadingTemplate ?
            <>
            <Paper elevation={3} sx={classes.formSection}>
                <Grid container spacing={1} direction="row">
                    <Grid item xs={12} sm={6}>
                        <Grid container sx={{paddingRight: 1}} spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="shipmentType"
                                    control={control}
                                    defaultValue={loadTemplateDetails ? loadTemplateDetails.ShipmentType : ""}
                                    render={({ field }) => 
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="shipmentTypeLabel" sx={{marginTop: 2}}>Type of Shipment</InputLabel>
                                            <Select labelId="shipmentTypeLabel" id="shipmentType" sx={classes.selectInput} {...field} error={errors.shipmentType ? true : false} label="Type of Shipment">
                                                <MenuItem value="tlRoad">TL Road</MenuItem>
                                                <MenuItem value="tlIntermodal">TL Intermodal</MenuItem>
                                                <MenuItem value="ltl">LTL</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.shipmentType?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="equipmentType"
                                    control={control}
                                    defaultValue={loadTemplateDetails ? loadTemplateDetails.EquipmentType : ""}
                                    render={({ field }) => 
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="equipmentTypeLabel" sx={{marginTop: 2}}>Equipment Type</InputLabel>
                                            <Select labelId="equipmentTypeLabel" id="equipmentType" sx={classes.selectInput} {...field} error={errors.equipmentType ? true : false} label="Equipment Type">
                                                <MenuItem value="dryVan">Dry Van</MenuItem>
                                                <MenuItem value="flatBed">Flat Bed</MenuItem>
                                                <MenuItem value="pupTruck">Pup Truck</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.equipmentType?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="goodsClassification"
                                    control={control}
                                    defaultValue={loadTemplateDetails ? loadTemplateDetails.GoodsClassification : ""}
                                    render={({ field }) => 
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="goodsClassificationLabel" sx={{marginTop: 2}}>Classification of Goods</InputLabel>
                                            <Select labelId="goodsClassificationLabel" id="goodsClassification" sx={classes.selectInput} {...field} error={errors.goodsClassification ? true : false} label="Classification of Goods">
                                                <MenuItem value="50">50 - Durable freight that fits on a standard 40" x 48" pallet</MenuItem>
                                                <MenuItem value="55">55 - Bricks, cement, hardwood flooring, construction materials</MenuItem>
                                                <MenuItem value="60">60 - Car accessories, car parts</MenuItem>
                                                <MenuItem value="65">65 - Car accessories and parts, boxed books, bottled drinks</MenuItem>
                                                <MenuItem value="70">70 - Car accessories and parts, auto engines, food items</MenuItem>
                                                <MenuItem value="77.5">77.5 - Tires, bathroom fixtures</MenuItem>
                                                <MenuItem value="85">85 - Crated machinery, cast iron stoves</MenuItem>
                                                <MenuItem value="92.5">92.5 - Computers, monitors, refrigerators</MenuItem>
                                                <MenuItem value="100">100 - Car covers, canvas, boat covers, wine cases, caskets</MenuItem>
                                                <MenuItem value="110">110 - Cabinets, framed art, table saws</MenuItem>
                                                <MenuItem value="125">125 - Small home appliances</MenuItem>
                                                <MenuItem value="150">150 - Auto sheet metal, bookcases</MenuItem>
                                                <MenuItem value="175">175 - Clothing, couches, stuffed furniture</MenuItem>
                                                <MenuItem value="200">200 - Sheet metal parts, aluminum tables, packaged mattresses, aircraft parts</MenuItem>
                                                <MenuItem value="250">250 - Mattresses and box springs, plasma TVs, bamboo furniture</MenuItem>
                                                <MenuItem value="300">300 - Model boats, assembled chairs, tables, wood cabinets</MenuItem>
                                                <MenuItem value="400">400 - Deer antlers</MenuItem>
                                                <MenuItem value="500">500 - Gold dust, ping pong balls</MenuItem>
                                                <MenuItem value="999">999 - Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.goodsClassification?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="loadDescription"
                                    control={control}
                                    defaultValue={loadTemplateDetails ? loadTemplateDetails.LoadDescription : ""}
                                    render={({ field }) => 
                                        <TextField 
                                            label="Load Description" 
                                            variant="outlined" 
                                            margin="normal" 
                                            size="small"
                                            sx={{width: '100%'}} 
                                            {...field} 
                                            error={errors.loadDescription ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.loadDescription?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="generalComments"
                                    control={control}
                                    defaultValue={loadTemplateDetails ? loadTemplateDetails.GeneralComments : ""}
                                    render={({ field }) => 
                                        <TextField 
                                            label="General Comments (Optional)" 
                                            variant="outlined" 
                                            multiline
                                            rows={3}
                                            margin="normal" 
                                            sx={classes.formInput}
                                            {...field} 
                                            error={errors.generalComments ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.generalComments?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Grid container sx={{paddingRight: 1}} spacing={1}>
                            <Grid item xs={12}>
                                <Controller
                                    name="serviceLevel"
                                    control={control}
                                    defaultValue={loadTemplateDetails ? loadTemplateDetails.ServiceLevel : ""}
                                    render={({ field }) => 
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="serviceLevelLabel" sx={{marginTop: 2}}>Service Level</InputLabel>
                                            <Select labelId="serviceLevelLabel" id="serviceLevel" sx={classes.selectInput} {...field} error={errors.serviceLevel ? true : false} label="Service Level">
                                                <MenuItem value="regular">Regular</MenuItem>
                                                <MenuItem value="expedited">Expedited</MenuItem>
                                                <MenuItem value="team">Team</MenuItem>
                                            </Select>
                                        </FormControl>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.serviceLevel?.message}
                            </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="palletHeight"
                                    control={control}
                                    defaultValue={loadTemplateDetails ? loadTemplateDetails.PalletHeight : ""}
                                    render={({ field }) => 
                                        <TextField 
                                            label="Pallet Height" 
                                            variant="outlined" 
                                            margin="normal" 
                                            size="small"
                                            sx={{width: '100%'}} 
                                            {...field} 
                                            error={errors.palletHeight ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.palletHeight?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {!customPalletDimensions ? 
                                    <>
                                    <Controller
                                        name="palletDimensions"
                                        control={control}
                                        defaultValue={loadTemplateDetails ? loadTemplateDetails.PalletDimensions : ""}
                                        render={({ field }) => 
                                            <FormControl fullWidth size="small">
                                                <InputLabel id="palletDimensionsLabel" sx={{marginTop: 2}}>Pallet Dimensions</InputLabel>
                                                <Select labelId="palletDimensionsLabel" id="palletDimensions" sx={classes.selectInput} {...field} error={errors.palletDimensions ? true : false} label="Pallet Dimensions"
                                                    onChange={(e) => {
                                                        if(e.target.value === 'custom'){
                                                            setCustomPalletDimensions(true);
                                                            setValue('palletDimensions', '');
                                                        }else{
                                                            setValue('palletDimensions', e.target.value);
                                                        }
                                                    }}
                                                >
                                                    {palletDimensionOptions.map((dimension) => (
                                                        <MenuItem key={dimension} value={dimension}>{dimension}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        }
                                    />
                                    <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                        {errors.palletDimensions?.message}
                                    </Typography>
                                    </>
                                :
                                    <>
                                    <Controller
                                        name="palletDimensions"
                                        control={control}
                                        defaultValue={loadTemplateDetails ? loadTemplateDetails.PalletDimensions : ""}
                                        render={({ field }) => 
                                            <TextField 
                                                label="Pallet Dimensions" 
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            <IconWithTooltip />
                                                        </InputAdornment>
                                                    )
                                                }}
                                                variant="outlined" 
                                                margin="normal" 
                                                size="small"
                                                sx={{width: '100%'}} 
                                                {...field} 
                                                error={errors.palletDimensions ? true : false}
                                            />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.palletDimensions?.message}
                                </Typography>
                                </>
                            }
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="trailerType"
                                    control={control}
                                    defaultValue={loadTemplateDetails ? loadTemplateDetails.TrailerType : ""}
                                    render={({ field }) => 
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="trailerTypeLabel" sx={{marginTop: 2}}>Trailer Type</InputLabel>
                                            <Select labelId="trailerTypeLabel" id="trailerType" sx={classes.selectInput} {...field} error={errors.trailerType ? true : false} label="Trailer Type">
                                                <MenuItem value="48Or 53Tandem">Tandem - 48' OR 53'</MenuItem>
                                                <MenuItem value="48Tandem">Tandem - 48'</MenuItem>
                                                <MenuItem value="53Tandem">Tandem - 53'</MenuItem>
                                                <MenuItem value="superB">Super B</MenuItem>
                                                <MenuItem value="48Tridem">Tridem - 48'</MenuItem>
                                                <MenuItem value="53Tridem">Tridem - 53'</MenuItem>
                                                <MenuItem value="48Or 53Tridem">Tridem - 48' OR 53'</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.trailerType?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Typography sx={{fontWeight: 'bold'}}>Check Any If Required</Typography>
                        <Grid style={classes.radioGroupStyle}>
                            <FormControlLabel
                                label="Pallet Jack Required"
                                control={
                                    <Controller 
                                        name="palletJackRequired"
                                        control={control}
                                        defaultValue={loadTemplateDetails ? loadTemplateDetails.PalletJackRequired : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Power Tailgate Required"
                                control={
                                    <Controller 
                                        name="powerTailgateRequired"
                                        control={control}
                                        defaultValue={loadTemplateDetails ? loadTemplateDetails.PowerTailgateRequired : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Load Is Stackable"
                                control={
                                    <Controller 
                                        name="stackableLoad"
                                        control={control}
                                        defaultValue={loadTemplateDetails ? loadTemplateDetails.StackableLoad : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Flatbed to be Tarped"
                                control={
                                    <Controller 
                                        name="flatbedTarped"
                                        control={control}
                                        defaultValue={loadTemplateDetails ? loadTemplateDetails.FlatbedTarped : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Load Contains Hazardous Goods"
                                control={
                                    <Controller 
                                        name="hazardous"
                                        control={control}
                                        defaultValue={loadTemplateDetails ? loadTemplateDetails.Hazardous : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            </>
            : null}
        </>
    );
}

export default LoadInfoForm;
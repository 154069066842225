import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../_actions';
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormControl } from '@mui/material';
import Alert from '@mui/material/Alert';

const classes = {
    formHeader: {
        fontSize: 20,
        paddingBottom: 1
    },
    formSection: {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        marginBottom: 2,
    },
    formInput: {
        width: '100%',
    },
    datePickerStyle: {
        marginTop: 2,
    },
    buttonGroup: {
        width: '100%'
    },
    postLoadForQuotingButton: {
        textTransform: 'none',
        width: '50%'
    },
    sendQuoteLetterButton: {
        textTransform: 'none',
        width: '50%',
        backgroundColor: '#0D9276',
        '&:hover': {
			backgroundColor: '#0D9276',
		},
    }
};

const validationSchema = Yup.object().shape({
	quoteDateTime: Yup.date()
        .required('Quote date/time is required')
        .typeError('Quote date/time is required'),
});

const validationSchema2 = Yup.object().shape({
	quoteDateTime2: Yup.date()
        .required('Quote date/time is required')
        .typeError('Quote date/time is required'),
    email: Yup.string()
        .required('Email is required')
        .matches(/.+@.+\..+/, 'Please enter a valid email'),
});

function RequestQuoteTab(){
    let { loadId } = useParams();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.quote.loading);

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });

	const { control: control2, handleSubmit: handleSubmit2, reset: reset2, register: register2, watch: watch2, formState: { errors: errors2, submitCount: submitCount2 }, getValues: getValues2, setValue: setValue2, trigger: trigger2} = useForm({
        resolver: yupResolver(validationSchema2)
    });


    useEffect(() => { 

    }, []);

    const onSubmit = data => {
        data.loadId = loadId;
        console.log(data);
        //dispatch(quoteActions.postLoadForQuoting(data));
    };

    const checkBoxChange = () => {
        setValue('sendQuoteLetter', !getValues('sendQuoteLetter'));
    };

    const checkBoxChange2 = () => {
        setValue2('sendQuoteLetter2', !getValues('sendQuoteLetter2'));
    };

    const sendToIndividual = data => {
        data.loadId = loadId;
        console.log(data);
        //dispatch(quoteActions.postLoadForIndividualQuoting(data));
    };

    return (
        <div style={{ width: '100%' }}>
        <Alert severity="info">
            <Typography style={{ fontWeight: 'bold' }}>You are able to request a quote from all vendors in the relevant service area.</Typography>
            <Typography style={{ fontWeight: 'bold' }}>Or you can request a quote from a specific vendor by entering their email.</Typography>
        </Alert>
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography sx={classes.formHeader}>Quote Required By</Typography>
                <Paper elevation={3} sx={classes.formSection}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="quoteDateTime"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <FormControl fullWidth sx={classes.datePickerStyle}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    slotProps={{
                                                        textField: {
                                                            error: false,
                                                            size: 'small'
                                                        },
                                                    }}
                                                    id="quoteDateTime"
                                                    {...field}
                                                    error={errors.quoteDateTime ? true : false}
                                                    label="Date and Time"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.quoteDateTime?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Controller
                                    name="quoteComments"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <TextField 
                                            label="Quoting Comments for Freight Vendors (Optional)" 
                                            variant="outlined" 
                                            multiline
                                            rows={3}
                                            margin="normal" 
                                            sx={classes.formInput}
                                            {...field} 
                                            error={errors.quoteComments ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.quoteComments?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        {!loading ? 
                        <>
                        <ButtonGroup variant="contained" sx={classes.buttonGroup}>
                            <Button
                                type="submit"
                                sx={classes.postLoadForQuotingButton}
                                startIcon={<UploadIcon />}
                            > 
                                Post Load for Quoting by All Vendors
                            </Button>
                            <Button
                                sx={classes.sendQuoteLetterButton}
                                onClick={() => checkBoxChange()}
                                startIcon={
                                    <FormControlLabel
                                        label="Send quote letter"
                                        control={
                                            <Controller 
                                                name="sendQuoteLetter"
                                                control={control}
                                                defaultValue={true}
                                                render={({ field }) => 
                                                    <Checkbox 
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={() => checkBoxChange()}
                                                        sx={{
                                                            [`&, &.${checkboxClasses.checked}`]: {
                                                                color: 'white',
                                                            },
                                                        }}
                                                    />
                                                }
                                            />
                                        }
                                    />
                                }
                            >    
                            </Button>
                        </ButtonGroup>
                        </>
                        : <LinearProgress />}
                    </form>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Typography sx={classes.formHeader}>Send to Individual</Typography>
                <Paper elevation={3} sx={classes.formSection}>
                    <form onSubmit={handleSubmit2(sendToIndividual)}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="quoteDateTime2"
                                    control={control2}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <FormControl fullWidth sx={classes.datePickerStyle}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    slotProps={{
                                                        textField: {
                                                            error: false,
                                                            size: 'small'
                                                        },
                                                    }}
                                                    id="quoteDateTime2"
                                                    {...field}
                                                    error={errors2.quoteDateTime2 ? true : false}
                                                    label="Date and Time"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors2.quoteDateTime2?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="email"
                                    control={control2}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <TextField 
                                            label="Email" 
                                            variant="outlined" 
                                            margin="normal" 
                                            size='small'
                                            sx={classes.formInput}
                                            {...field} 
                                            error={errors2.email ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors2.email?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Controller
                                    name="quoteComments2"
                                    control={control2}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <TextField 
                                            label="Quoting Comments (Optional)" 
                                            variant="outlined" 
                                            multiline
                                            rows={3}
                                            margin="normal" 
                                            sx={classes.formInput}
                                            {...field} 
                                            error={errors2.quoteComments2 ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors2.quoteComments2?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                        {!loading ? 
                        <>
                        <ButtonGroup variant="contained" sx={classes.buttonGroup}>
                            <Button
                                type="submit"
                                sx={classes.postLoadForQuotingButton}
                                startIcon={<UploadIcon />}
                            > 
                                Post Load for Quoting
                            </Button>
                            <Button
                                sx={classes.sendQuoteLetterButton}
                                onClick={() => checkBoxChange2()}
                                startIcon={
                                    <FormControlLabel
                                        label="Send quote letter"
                                        control={
                                            <Controller 
                                                name="sendQuoteLetter"
                                                control={control}
                                                defaultValue={true}
                                                render={({ field }) => 
                                                    <Checkbox 
                                                        {...field}
                                                        checked={field.value}
                                                        onChange={() => checkBoxChange2()}
                                                        sx={{
                                                            [`&, &.${checkboxClasses.checked}`]: {
                                                                color: 'white',
                                                            },
                                                        }}
                                                    />
                                                }
                                            />
                                        }
                                    />
                                }
                            >    
                            </Button>
                        </ButtonGroup>
                        </>
                        : <LinearProgress />}
                    </form>
                </Paper>
            </Grid>
        </Grid>

        </div>
    );
}

export default RequestQuoteTab;
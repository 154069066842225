import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import { phoneNumberAutoFormat } from '../../_helpers';
import CheckIcon from '@mui/icons-material/Check';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';

const classes = {
    formPaper: {
        padding: 2,
        width: '50%'
    },
    formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
    updateButton: {
		textTransform: 'none',
		left: '40%',
		paddingLeft: 3,
		paddingRight: 3,
		marginTop: 3,
        backgroundColor: '#1565C0',
		'&:hover': {
			backgroundColor: '#1565C0',
		},
	},
};

const invalidDomains = ['gmail', 'yahoo', 'hotmail', 'outlook', 'icloud', 'aol', 'msn'];

const validationSchema = Yup.object().shape({
    companyCountry: Yup.string()
        .required('Country is required'),
    companyProvinceState: Yup.string()
        .required('Province/State is required'),
    companyCity: Yup.string()
        .required('City is required'),
    companyAddress: Yup.string()
        .required('Address is required'),
    companyPostalZip: Yup.string()
        .required('Postal/Zip is required'),
    currency: Yup.string()
        .required('Currency is required'),
    primaryContactPhone: Yup.string()
        .required('Phone number is required'),
    primaryContactEmail: Yup.string()
        .required('Email is required')
		.matches(/.+@.+\..+/, 'Please enter a valid email')
		.test(
			'Unauthorized Email Domain',
			'An unauthorized domain was entered. Please use your work email and avoid personal email services',
			(value) => {
				const currentDomain = value.substring(
					value.indexOf('@') + 1,
					value.indexOf('.')
				);

				return !invalidDomains.includes(currentDomain);
			}
		),
    companyLogo: Yup.mixed()
        .nullable()
        .notRequired()
		.test(
			'File Size',
			'File size is too large',
			(value) => {
				if(value){
					console.log(value);
					console.log('File size is: ' + value.size);
					return value && value.size < 2000000; //2MB
				}else{
					return true;
				}
			}
		)
		.test(
			'File Type',
			'We only support jpg or png files',
			(value) => {
				if(value){
					console.log('File type is: ' + value.type);
					return value && value.type === 'image/jpeg' || value.type === 'image/png'; 
				}else{
					return true;
				}
			}
		),
});

const provinceList = [
	{ Code: 'AB', Name: 'Alberta' },
	{ Code: 'BC', Name: 'British Columbia' },
	{ Code: 'MB', Name: 'Manitoba' },
	{ Code: 'NB', Name: 'New Brunswick' },
	{ Code: 'NL', Name: 'Newfoundland and Labrador' },
	{ Code: 'NS', Name: 'Nova Scotia' },
	{ Code: 'ON', Name: 'Ontario' },
	{ Code: 'PE', Name: 'Prince Edward Island' },
	{ Code: 'QC', Name: 'Quebec' },
	{ Code: 'SK', Name: 'Saskatchewan' },
	{ Code: 'NT', Name: 'Northwest Territories' },
	{ Code: 'NU', Name: 'Nunavut' },
	{ Code: 'YT', Name: 'Yukon' },
];

const stateList = [
	{ Code: 'AL', Name: 'Alabama' },
	{ Code: 'AK', Name: 'Alaska' },
	{ Code: 'AZ', Name: 'Arizona' },
	{ Code: 'AR', Name: 'Arkansas' },
	{ Code: 'AS', Name: 'American Samoa' },
	{ Code: 'CA', Name: 'California' },
	{ Code: 'CO', Name: 'Colorado' },
	{ Code: 'CT', Name: 'Connecticut' },
	{ Code: 'DE', Name: 'Delaware' },
	{ Code: 'DC', Name: 'District of Columbia' },
	{ Code: 'FL', Name: 'Florida' },
	{ Code: 'GA', Name: 'Georgia' },
	{ Code: 'GU', Name: 'Guam' },
	{ Code: 'HI', Name: 'Hawaii' },
	{ Code: 'ID', Name: 'Idaho' },
	{ Code: 'IL', Name: 'Illinois' },
	{ Code: 'IN', Name: 'Indiana' },
	{ Code: 'IA', Name: 'Iowa' },
	{ Code: 'KS', Name: 'Kansas' },
	{ Code: 'KY', Name: 'Kentucky' },
	{ Code: 'LA', Name: 'Louisiana' },
	{ Code: 'ME', Name: 'Maine' },
	{ Code: 'MD', Name: 'Maryland' },
	{ Code: 'MA', Name: 'Massachusetts' },
	{ Code: 'MI', Name: 'Michigan' },
	{ Code: 'MN', Name: 'Minnesota' },
	{ Code: 'MS', Name: 'Mississippi' },
	{ Code: 'MO', Name: 'Missouri' },
	{ Code: 'MT', Name: 'Montana' },
	{ Code: 'NE', Name: 'Nebraska' },
	{ Code: 'NV', Name: 'Nevada' },
	{ Code: 'NH', Name: 'New Hampshire' },
	{ Code: 'NJ', Name: 'New Jersey' },
	{ Code: 'NM', Name: 'New Mexico' },
	{ Code: 'NY', Name: 'New York' },
	{ Code: 'NC', Name: 'North Carolina' },
	{ Code: 'ND', Name: 'North Dakota' },
	{ Code: 'MP', Name: 'Northern Mariana Islands' },
	{ Code: 'OH', Name: 'Ohio' },
	{ Code: 'OK', Name: 'Oklahoma' },
	{ Code: 'OR', Name: 'Oregon' },
	{ Code: 'PA', Name: 'Pennsylvania' },
	{ Code: 'PR', Name: 'Puerto Rico' },
	{ Code: 'RI', Name: 'Rhode Island' },
	{ Code: 'SC', Name: 'South Carolina' },
	{ Code: 'SD', Name: 'South Dakota' },
	{ Code: 'TN', Name: 'Tennessee' },
	{ Code: 'TX', Name: 'Texas' },
	{ Code: 'TT', Name: 'Trust Territories' },
	{ Code: 'UT', Name: 'Utah' },
	{ Code: 'VT', Name: 'Vermont' },
	{ Code: 'VA', Name: 'Virginia' },
	{ Code: 'VI', Name: 'Virgin Islands' },
	{ Code: 'WA', Name: 'Washington' },
	{ Code: 'WV', Name: 'West Virginia' },
	{ Code: 'WI', Name: 'Wisconsin' },
	{ Code: 'WY', Name: 'Wyoming' },
];

function CompanyInformation() {
    const [selectedCountry, setSelectedCountry] = useState('Canada');
    const [fileValue, setFileValue] = useState('');
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.authentication.loading);
    const companyInfo = useSelector(state => state.authentication.companyInfo); 

    const { control, handleSubmit, reset, register, watch, formState: { errors, submitCount }, getValues, setValue, trigger } = useForm({
        resolver: yupResolver(validationSchema)
    });

    useEffect(() => { 
        dispatch(userActions.getCompanyInfo(user.UserId));
    }, []);

    useEffect(() => { 
        if(companyInfo){
            setSelectedCountry(companyInfo.CompanyCountry);
        }
	}, [companyInfo]);

    const onPhoneNumberChange = ({target: {value}}) => {
        let formattedNumber = phoneNumberAutoFormat(value);
        setValue('primaryContactPhone', formattedNumber); 
    };

    const renderProvinceStateList = () => {
		if(selectedCountry === 'Canada'){
			return provinceList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Name }>{ state.Name }</MenuItem>);
			});
		}else if(selectedCountry === 'USA'){
			return stateList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Name }>{ state.Name }</MenuItem>);
			});
		}
    };

    const editCompanyInfo = (data) => {
        data.editorEmail = user.UserId;
        //console.log(data);
        dispatch(userActions.updateCompanyInfo(data));
    };

    return (
        <Container>
            {!loading && companyInfo ?
            <Paper sx={classes.formPaper}>
                <form onSubmit={handleSubmit(editCompanyInfo)}>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="companyName"
                                control={control}
                                defaultValue={companyInfo.CompanyName}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Company Name" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.companyName ? true : false}
                                        disabled
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyName?.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="companyCountry"
                                control={control}
                                defaultValue={companyInfo.CompanyCountry}
                                render={({ field }) => 
                                    <TextField 
                                        label="Country"
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.companyCountry ? true : false}
                                        onChange={(e) => {
                                            field.onChange(e); 
                                            setSelectedCountry(e.target.value); 
                                            setValue('companyProvinceState', '');
                                            if (submitCount > 0)
                                                trigger('companyProvinceState');
                                        }}
                                    >
                                        <MenuItem value="Canada">Canada</MenuItem>
                                        <MenuItem value="USA">USA</MenuItem>
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyCountry?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="companyProvinceState"
                                control={control}
                                defaultValue={companyInfo.CompanyProvinceState}
                                render={({ field }) => 
                                    <TextField 
                                        label="Province/State"
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.companyProvinceState ? true : false}
                                    >
                                        {renderProvinceStateList()}
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyProvinceState?.message}
                            </Typography>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <Controller
                                name="companyCity"
                                control={control}
                                defaultValue={companyInfo.CompanyCity}
                                render={({ field }) => 
                                    <TextField 
                                        label="City" 
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.companyCity ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyCity?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="companyAddress"
                                control={control}
                                defaultValue={companyInfo.CompanyAddress}
                                render={({ field }) => 
                                    <TextField 
                                        size="small"
                                        label="Address" 
                                        variant="outlined" 
                                        margin="normal" 
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.companyAddress ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyAddress?.message}
                            </Typography>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <Controller
                                name="companyPostalZip"
                                control={control}
                                defaultValue={companyInfo.CompanyPostalZip}
                                render={({ field }) => 
                                    <TextField 
                                        label="Postal/Zip Code" 
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.companyPostalZip ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.companyPostalZip?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item  xs={12} sm={6}>
                            <Controller
                                name="currency"
                                control={control}
                                defaultValue={companyInfo.Currency ? companyInfo.Currency : ''}
                                render={({ field }) => 
                                    <TextField 
                                        label="Currency"
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        select
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.currency ? true : false}
                                    >
                                        <MenuItem value="CAD">CAD</MenuItem>
                                        <MenuItem value="USD">USD</MenuItem>
                                    </TextField>
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.currency?.message}
                            </Typography>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <Controller
                                name="primaryContactPhone"
                                control={control}
                                defaultValue={companyInfo.PrimaryContactPhone}
                                render={({ field }) => 
                                    <TextField 
                                        label="Primary Contact Phone" 
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.primaryContactPhone ? true : false}
                                        onChange={onPhoneNumberChange}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.primaryContactPhone?.message}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={classes.formRow}>
                        <Grid item  xs={12} sm={6}>
                            <Controller
                                name="primaryContactEmail"
                                control={control}
                                defaultValue={companyInfo.PrimaryContactEmail}
                                render={({ field }) => 
                                    <TextField 
                                        label="Primary Contact Email" 
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        sx={classes.formInput} 
                                        {...field} 
                                        error={errors.primaryContactEmail ? true : false}
                                    />
                                }
                            />
                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                {errors.primaryContactEmail?.message}
                            </Typography>
                        </Grid>
                        <Grid item  xs={12} sm={6}>
                            <Typography sx={{textAlign: 'right', paddingTop: 1}}>Update Company Logo</Typography>
							<Controller
                                name="companyLogo"
                                control={control}
                                defaultValue={null}
                                render={({ field }) =>
                                    <input 
                                        accept="image/*" 
                                        id="companyLogo" 
                                        name="companyLogo" 
                                        type="file" 
                                        onChange={(event)=> { 
                                            const file = event.target.files[0];
                                            setFileValue(file); // Store the file object
                                            field.onChange(file); // Pass the file object to the controller
                                        }}
                                        error={errors.companyLogo ? 1 : 0} />
                                }
                            />
							<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
								{errors.companyLogo?.message}
							</Typography>
						</Grid>
                    </Grid>
                    {!loading ? 
                    <Button
                        type="submit"
                        variant="contained"
                        sx={classes.updateButton}
                        startIcon={<CheckIcon sx={{marginRight: -0.5}}/>}
                    > 
                        Update
                    </Button>
                    : <LinearProgress />}
                </form>
            </Paper>
            : null}
        </Container>
    );
}

export default CompanyInformation;
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadActions } from '../../_actions';
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ShippingInfo from './ShippingInfo';
import LoadInfo from './LoadInfo';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const classes = {
	editButton: {
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
		left: '41%',
		marginTop: 3,
		paddingLeft: 3.5,
		paddingRight: 3.5,
	},
	deleteButton: {
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
		left: '43%',
		marginTop: 3,
		paddingLeft: 2.5,
		paddingRight: 2.5,
	},
};

const validationSchema = Yup.object().shape({
	shipmentType: Yup.string()
		.required('Shipment type is required'),
	equipmentType: Yup.string()
		.required('Equipment type is required'),
	goodsClassification: Yup.string()
		.required('Classification of goods is required'),
	loadDescription: Yup.string()
		.required('Load description is required'),
	serviceLevel: Yup.string()
		.required('Service Level is required'),
	palletHeight: Yup.string()
		.required('Pallet Height is required'),
	palletDimensions: Yup.string()
		.required('Pallet dimensions is required'),
	trailerType: Yup.string()
		.required('Trailer type is required'),
	freightCost: Yup.string()
		.required('Freight cost is required'),
});

function GeneralTab(props){
    const { loadId } = props;
    const dispatch = useDispatch();
    const loading = useSelector(state => state.load.loading);
    const loadDetails = useSelector(state => state.load.loadDetails);
	const user = useSelector(state => state.authentication.user);

    useEffect(() => { 
        dispatch(loadActions.getLoadDetails(loadId));
    }, []);

    // HAVING YUP VALIDATIONSCHEMA THATS NOT BEING USED WILL CAUSE THE FORM TO NOT SUBMIT
	const methods = useForm({
        resolver: yupResolver(validationSchema)
    });

    const editLoad = data => {
		data.id = loadDetails.Id;
		data.modifiedBy = user.UserId;
        dispatch(loadActions.editLoadDetails(data));
    };

	const deleteLoad = () => {
		if(window.confirm('Are you sure you want to delete this load?')){
			const deletedBy = user.UserId;
			dispatch(loadActions.deleteLoad(loadDetails.Id, deletedBy));
		}
	};

    return (
        <FormProvider {...methods}>
            <form>
                <ShippingInfo />
                <LoadInfo />
                {!loading ? 
                <>
                <Button
                    type="submit"
                    variant="contained"
                    sx={classes.editButton}
                    startIcon={<EditIcon />}
                    onClick={methods.handleSubmit(editLoad)}
                > 
                    Edit
                </Button>
                <Button
                    variant="contained"
                    sx={classes.deleteButton}
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={() => deleteLoad()}
                >
                    Delete
                </Button>
                </>
                : <LinearProgress />}
            </form>
        </FormProvider>
    );
}

export default GeneralTab;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { loadActions } from '../../../_actions';
import { useNavigate } from "react-router-dom";
import Header from '../../../components/Header';
import GeneralTab from '../../../components/LoadDetails/GeneralTab';
import PickupsDeliveriesTab from '../../../components/LoadDetails/PickupsDeliveriesTab';
import RequestQuoteTab from '../../../components/LoadDetails/RequestQuoteTab';
import AwardQuoteTab from '../../../components/LoadDetails/AwardQuoteTab';
import ShippingInstructionsTab from '../../../components/LoadDetails/ShippingInstructionsTab';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Footer from '../../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden',
	},
	contentContainer: {
		paddingTop: 2
	},
	pageHeader: {
		color: '#2c88d9',
		marginBottom: 3
	},
	tabTitle: {
		textTransform: 'none',
		fontWeight: 'bold',
	},
	tabContent: {
		paddingLeft: 0
	},
	editButton: {
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
		left: '41%',
		marginTop: 3,
		paddingLeft: 3.5,
		paddingRight: 3.5,
	},
	deleteButton: {
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
		left: '43%',
		marginTop: 3,
		paddingLeft: 2.5,
		paddingRight: 2.5,
	},
};

function LoadDetailsPage() {
	let { tabName, loadId } = useParams();
	const navigate = useNavigate();

    useEffect(() => { 
        document.title = "DFQ - Load Details";
    }, []);

	const handleTabChange = (event, newTab) => {
		navigate(`/loads/details/${newTab}/${loadId}`);
    };

	return (
		<>
		<CssBaseline />
        <Header />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Container sx={classes.contentContainer}>
				<Typography variant="h4" sx={classes.pageHeader}>Load Details</Typography>
					<Box>
						<TabContext value={tabName}>
							<Tabs
								value={tabName}
								onChange={handleTabChange}
								aria-label="Horizontal tabs"	
							>
								<Tab sx={classes.tabTitle} label="General" value="general" />
								<Tab sx={classes.tabTitle} label="Pickups/Deliveries" value="locations" />
								<Tab sx={classes.tabTitle} label="Request Quote" value="requestquote" /> 
								<Tab sx={classes.tabTitle} label="Award Quote" value="awardquote" />
								<Tab sx={classes.tabTitle} label="Shipping Instructions" value="shippinginstructions" />
							</Tabs>
							<TabPanel value="general" index={0} sx={classes.tabContent}>
								<GeneralTab loadId={loadId}/>
							</TabPanel>
							<TabPanel value="locations" index={1}  sx={classes.tabContent}>
								<PickupsDeliveriesTab />
							</TabPanel>
							<TabPanel value="requestquote" index={2} sx={classes.tabContent}>
								<RequestQuoteTab />
							</TabPanel>
							<TabPanel value="awardquote" index={3} sx={classes.tabContent}>
								<AwardQuoteTab />
							</TabPanel>
							<TabPanel value="shippinginstructions" index={4} sx={classes.tabContent}>
								<ShippingInstructionsTab />
							</TabPanel>
						</TabContext>
					</Box>
			</Container>
		</Container>
        <Footer />
		</>
	);
}

export { LoadDetailsPage };
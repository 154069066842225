import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { loadActions } from '../../../_actions';
import { formatShipmentType, numberWithCommas, addCapSpace } from '../../../_helpers';
import Header from '../../../components/Header';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import LinearProgress from '@mui/material/LinearProgress';
import Footer from '../../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden',
		padding: '2.5%',
	},
	pageHeader: {
		color: '#2c88d9',
		marginBottom: 3,
	},
	loadDetails: {
		padding: 2,
		display: 'inline-block',
		width: '100%'
	},
	detailLabel: {
		fontWeight: 'bold'
	},
    gridContainer: {
		border: '3px solid #c3cfd9',
		borderRadius: '0px', // Optional: Add border radius for rounded corners
		padding: '0px', // Optional: Add padding for spacing
		backgroundColor: 'white',
	},
	gridColumn: {
		padding: '8px', // Optional: Add padding for spacing
	},
	gridColumnWithBorder: {
		borderRight: '3px solid #c3cfd9', // Add vertical border to columns
		height: '100%',
	},
	typographyBlue: {
		color: '#2c88d9',
		fontWeight: 'bold'
	},
	typographyLarge: {
		fontSize: '1.5rem', // Adjust as needed
	},
	gridColumnWithNoTopBorder: {
		borderTop: 'none',
	},
	textOverflowStyle: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		'&:hover': {
			overflow: 'visible',
			whiteSpace: 'normal',
		}
	},
    locationListHeader: {
        fontSize: 20,
		color: '#2c88d9',
    },
	locationListContainer: {
		marginTop: 3,
	},
    tableStyle: {
        whiteSpace: 'nowrap',
        maxHeight: 335,
		backgroundColor: 'white',
    },
	tableBorderFull: {
		border: '3px solid #c3cfd9'
	},
	tableBorderNoLeft: {
		border: '3px solid #c3cfd9',
		borderLeft: 'none'
	},
	tableBorderNoTop: {
		border: '3px solid #c3cfd9',
		borderTop: 'none'
	},
	tableBorderNoTopLeft: {
		border: '3px solid #c3cfd9',
		borderTop: 'none',
		borderLeft: 'none'
	},
	tableHeaderStyle: {
		fontWeight: 'bold'
	},
	confirmButton: {
		backgroundColor: '#0D9276',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
		left: '41%',
		marginTop: 3,
		'&:hover': {
			backgroundColor: '#0D9276',
		},
	},
	editButton: {
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
		left: '43%',
		marginTop: 3,
		paddingLeft: 3.5,
		paddingRight: 3.5,
	},
	checkBoxStyle: {
		float: 'right',
		marginTop: 3,
	}
};		

function ReviewLoadPage() {
	const [saveAsTemplate, setSaveAsTemplate] = useState(false);
	const [loadInfo, setLoadInfo] = useState(null);
	const [pickups, setPickups] = useState([]);
	const [deliveries, setDeliveries] = useState([]);
	const [palletCount, setPalletCount] = useState(0);
	const [totalWeight, setTotalWeight] = useState(0);
	const user = useSelector(state => state.authentication.user);
	const loading = useSelector(state => state.load.loading);
    const dispatch = useDispatch();
	let location = useLocation();

    useEffect(() => { 
        document.title = "DFQ - Review Load";
		if(location.state === null){
			window.history.pushState({}, undefined, `/loads/new`);
			window.location.reload();
		}
		setLoadInfo(location.state.loadInfo);
		setPickups(location.state.loadInfo.PickupList);
		setDeliveries(location.state.loadInfo.DeliveryList);
		calculatePalletCount(location.state.loadInfo.PickupList);
		calculateTotalWeight(location.state.loadInfo.PickupList);
    }, []);

	const createNewLoad = () => {
		loadInfo.companyId = user.CompanyId;
		loadInfo.userId = user.UserId;
		loadInfo.firstPickupLocation = pickups[0].pickupCity + ', ' + pickups[0].pickupStateProv;
		loadInfo.lastDeliveryLocation = deliveries[deliveries.length - 1].deliveryCity + ', ' + deliveries[deliveries.length - 1].deliveryStateProv;
		loadInfo.firstPickupDate = pickups[0].pickupDate;
		loadInfo.lastDeliveryDate = deliveries[deliveries.length - 1].deliveryDate;
		loadInfo.palletCount = palletCount;
		loadInfo.weight = totalWeight;
		loadInfo.saveAsTemplate = saveAsTemplate;
        dispatch(loadActions.createLoad(loadInfo));
    };

	const convertBoolToYN = input => {
		if(input){
			return 'Yes';
		}else{
			return 'No';
		}
	};

	const calculatePalletCount = pickupLocations => {
		const numberOfPallets = pickupLocations.reduce((count, { pickupPalletCount }) => count + pickupPalletCount, 0);
		const numberOfPalletsRounded = Math.round((numberOfPallets + Number.EPSILON) * 100) / 100;
		setPalletCount(numberOfPalletsRounded);
	};

	const calculateTotalWeight = pickupLocations => {
		const loadWeight = pickupLocations.reduce((count, { pickupWeight }) => count + pickupWeight, 0);
		const loadWeightRounded = Math.round((loadWeight + Number.EPSILON) * 100) / 100;
		setTotalWeight(loadWeightRounded);
	};

	const editLoadDetails = () => {
		window.history.go(-1);
		return false;
	};

	return (
		<>
		<CssBaseline />
        <Header />
		{loadInfo ? 
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			{/* Load Info Container */}
			<Container>
				<Typography variant="h4" sx={classes.pageHeader}>Review Load Details</Typography>
				{/* 1st Row */}
				<Grid container spacing={0} sx={classes.gridContainer}>
					<Grid item xs={4} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>First Pickup Location</Typography>
						<Typography variant="h6" sx={classes.typographyLarge}>{pickups[0].pickupCity}, {pickups[0].pickupStateProv} {pickups[0].pickupPostalZip}</Typography>
					</Grid>
					<Grid item xs={2} sx={{ ...classes.gridColumn, ...classes.gridColumnWithBorder }}>
						<Typography variant="body2" sx={classes.typographyBlue}>Date</Typography>
						<Typography variant="h6" sx={classes.typographyLarge}>{dayjs(pickups[0].pickupDate).format('MMM DD, YYYY')}</Typography>
					</Grid>
					<Grid item xs={4} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Last Delivery Location</Typography>
						<Typography variant="h6" sx={classes.typographyLarge}>{deliveries[deliveries.length - 1].deliveryCity}, {deliveries[deliveries.length - 1].deliveryStateProv} {deliveries[deliveries.length - 1].deliveryPostalZip}</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Date</Typography>
						<Typography variant="h6" sx={classes.typographyLarge}>{dayjs(deliveries[deliveries.length - 1].deliveryDate).format('MMM DD, YYYY')}</Typography>
					</Grid>
				</Grid>
				{/* 2nd Row */}
				<Grid container spacing={0} sx={{ ...classes.gridContainer, ...classes.gridColumnWithNoTopBorder }}>
					<Grid item xs={12} sx={classes.gridColumn}>
						<Typography variant="h6" sx={classes.typographyLarge}>{formatShipmentType(loadInfo.shipmentType)}: {palletCount} pallets </Typography>
					</Grid>
				</Grid>
				{/* 3rd Row */}
				<Grid container spacing={0} sx={{ ...classes.gridContainer, ...classes.gridColumnWithNoTopBorder }}>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Load Description</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Service Level</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Class of Goods</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Pallet Dimensions</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Pallet Height</Typography>
					</Grid>
				</Grid>
				{/* 4th Row */}
				<Grid container spacing={0} sx={{ ...classes.gridContainer, ...classes.gridColumnWithNoTopBorder }}>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{loadInfo.loadDescription}</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{addCapSpace(loadInfo.serviceLevel)}</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{loadInfo.goodsClassification}</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{loadInfo.palletDimensions}</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{loadInfo.palletHeight}</Typography>
					</Grid>
				</Grid>
				{/* 5th Row */}
				<Grid container spacing={0} sx={{ ...classes.gridContainer, ...classes.gridColumnWithNoTopBorder }}>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Total Weight</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Equipment Type</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Trailer Type</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Pickups</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>Drops</Typography>
					</Grid>
				</Grid>
				{/* 6th Row */}
				<Grid container spacing={0} sx={{ ...classes.gridContainer, ...classes.gridColumnWithNoTopBorder }}>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{numberWithCommas(totalWeight)} Lbs</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{addCapSpace(loadInfo.equipmentType)}</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{addCapSpace(loadInfo.trailerType)}</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{pickups.length}</Typography>
					</Grid>
					<Grid item xs={2} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>{deliveries.length}</Typography>
					</Grid>
				</Grid>
				{/* 7th Row */}
				<Grid container spacing={0} sx={{ ...classes.gridContainer, ...classes.gridColumnWithNoTopBorder }}>
					<Grid item xs={4} sx={{ ...classes.gridColumn, ...classes.gridColumnWithBorder }}>
						<Typography variant="body2" sx={classes.typographyBlue}>Special Handling</Typography>
					</Grid>
					<Grid item xs={8} sx={classes.gridColumn}>
						<Typography variant="body2" sx={classes.typographyBlue}>General Comments</Typography>
					</Grid>
				</Grid>
				{/* 8th Row */}
				<Grid container spacing={0} sx={{ ...classes.gridContainer, ...classes.gridColumnWithNoTopBorder }}>
					<Grid item xs={4} sx={{ ...classes.gridColumn, ...classes.gridColumnWithBorder }}>
						<Typography variant="body2" sx={classes.textOverflowStyle}>Pallet Jack Required: <span style={{float: 'right', paddingRight: '40%'}}>{convertBoolToYN(loadInfo.palletJackRequired)}</span></Typography>
						<Typography variant="body2" sx={classes.textOverflowStyle}>Power Tailgate Required: <span style={{float: 'right', paddingRight: '40%'}}>{convertBoolToYN(loadInfo.powerTailgateRequired)}</span></Typography>
						<Typography variant="body2" sx={classes.textOverflowStyle}>Stackable Load: <span style={{float: 'right', paddingRight: '40%'}}>{convertBoolToYN(loadInfo.stackableLoad)}</span></Typography>
						<Typography variant="body2" sx={classes.textOverflowStyle}>Flatbed to be Tarped: <span style={{float: 'right', paddingRight: '40%'}}>{convertBoolToYN(loadInfo.flatbedTarped)}</span></Typography>
						<Typography variant="body2" sx={classes.textOverflowStyle}>Hazardous: <span style={{float: 'right', paddingRight: '40%'}}>{convertBoolToYN(loadInfo.hazardous)}</span></Typography>
					</Grid>
					<Grid item xs={8} sx={classes.gridColumn}>
						<Typography variant="body2">{loadInfo.generalComments}</Typography>
					</Grid>
				</Grid>
			</Container>
			{/* Pickup List Container */}
			<Container sx={classes.locationListContainer}>
				<Typography sx={classes.locationListHeader}>Pickup List</Typography>
				<TableContainer sx={classes.tableStyle}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								<TableCell align={'left'} sx={{ ...classes.tableBorderFull, ...classes.tableHeaderStyle}}>Pickup Ref #</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Company Name</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Pickup Date</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Pallet Count</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Weight (Lbs)</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>City</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Address 1</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Address 2</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Postal/Zip Code</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Province/State</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Country</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Contact Name</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Contact Phone</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Contact Email</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Contact Info</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Instructions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{pickups && pickups.length > 0 ?
								pickups.map((row) => (
									<TableRow key={row.id}>
										<TableCell align="left" sx={classes.tableBorderNoTop}>{row.pickupRefNum}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.companyName}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.pickupDate ? dayjs(row.pickupDate).format('MMM DD, YYYY') : ''}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.pickupPalletCount}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.pickupWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.pickupCity}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.address1}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.address2}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.pickupPostalZip}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.pickupStateProv}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.pickupCountry}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.contactName}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.contactPhoneNumber}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.contactEmail}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.pickupContactInfo}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.pickupInstructions}</TableCell>
									</TableRow>
								))
                            : null}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
			{/* Delivery List Container */}
			<Container sx={classes.locationListContainer}>
				<Typography sx={classes.locationListHeader}>Delivery List</Typography>
				<TableContainer sx={classes.tableStyle}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
							<TableCell align={'left'} sx={{ ...classes.tableBorderFull, ...classes.tableHeaderStyle}}>Delivery Ref #</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Company Name</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Delivery Date</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Pallet Count</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Weight (Lbs)</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>City</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Address 1</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Address 2</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Postal/Zip Code</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Province/State</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Country</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Contact Name</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Contact Phone</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle }}>Contact Email</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Contact Info</TableCell>
								<TableCell align={'left'} sx={{ ...classes.tableBorderNoLeft, ...classes.tableHeaderStyle}}>Instructions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{deliveries && deliveries.length > 0 ?
								deliveries.map((row) => (
									<TableRow key={row.id}>
										<TableCell align="left" sx={classes.tableBorderNoTop}>{row.deliveryRefNum}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.companyName}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.deliveryDate ? dayjs(row.deliveryDate).format('MMM DD, YYYY') : ''}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.deliveryPalletCount}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.deliveryWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.deliveryCity}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.address1}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.address2}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.deliveryPostalZip}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.deliveryStateProv}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.deliveryCountry}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.contactName}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.contactPhoneNumber}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.contactEmail}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.deliveryContactInfo}</TableCell>
										<TableCell align="left" sx={classes.tableBorderNoTopLeft}>{row.deliveryInstructions}</TableCell>
									</TableRow>
								))
                            : null}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
			{/* Button/Checkbox Container */}
			<Container>
				{!loading ? 
				<>
				<FormControlLabel 
					label="Save as template"
					sx={classes.checkBoxStyle}
					control={
						<Checkbox 
							checked={saveAsTemplate}
							onChange={(e) => setSaveAsTemplate(e.target.checked)}
						/>
					}
				/>
				<Button
					disabled={pickups.length == 0 || deliveries.length == 0}
					type="submit"
					variant="contained"
					sx={classes.confirmButton}
					startIcon={<CheckIcon />}
					onClick={() => createNewLoad()}
				> 
					Confirm
				</Button>
				<Button
					variant="contained"
					sx={classes.editButton}
					startIcon={<EditIcon />}
					onClick={() => editLoadDetails()}
				>
					Edit
				</Button>
				</>
				: <LinearProgress />}
			</Container>
		</Container>
		: null}
        <Footer />
		</>
	);
}

export { ReviewLoadPage };
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import Header from '../../components/Header';
import LocationAddressBook from '../../components/Admin/LocationAddressBook';
import PersonalInformation from '../../components/Admin/PersonalInformation';
import CompanyInformation from '../../components/Admin/CompanyInformation';
import ResetPassword from '../../components/Admin/ResetPassword';
import ManageUsers from '../../components/Admin/ManageUsers';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		paddingLeft: 20
	},
	tabBox: {
		flexGrow: 1,
		display: 'flex',
		paddingTop: 8,
		paddingBottom: 5,
	},
	tabTitle: {
		textTransform: 'none',
	},
};

function AdminPage() {
	let { tabIndex } = useParams();
	const [tabValue, setTabValue] = useState('0');
    const dispatch = useDispatch();
	const user = useSelector(state => state.authentication.user);

    useEffect(() => { 
        document.title = "DFQ - Admin";
    }, []);

	// Had to change how tab change is handled so when a new location is added it will appear in the list of locations right away (Page refresh would reset the tab index to 0)
	const handleTabChange = (event, newTab) => {
        //setTabValue(newTab);
		window.history.pushState({}, undefined, `/admin/${newTab}`);
		window.location.reload();
    };

	return (
		<>
		<CssBaseline />
        <Header />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Box sx={classes.tabBox}>
				<TabContext value={tabIndex}>
					<Tabs
						orientation="vertical"
						value={tabIndex}
						onChange={handleTabChange}
						aria-label="Vertical tabs example"	
					>
						<Tab sx={classes.tabTitle} label="Personal Information" value="0" />
						{user.Role === "ADMIN" && 
							<Tab sx={classes.tabTitle} label="Company Information" value="1" /> 
						}
						<Tab sx={classes.tabTitle} label="Location Address Book" value="2" /> 
						<Tab sx={classes.tabTitle} label="Reset Password" value="3" />
						<Tab sx={classes.tabTitle} label="Manage Users" value="4" />
					</Tabs>
					<TabPanel value="0" index={0}><PersonalInformation /></TabPanel>
					{user.Role === "ADMIN" && 
						<TabPanel value="1" index={1}><CompanyInformation /></TabPanel> 
					}
					<TabPanel value="2" index={2}><LocationAddressBook /></TabPanel>
					<TabPanel value="3" index={3}><ResetPassword /></TabPanel>
					<TabPanel value="4" index={4}><ManageUsers /></TabPanel>
				</TabContext>
			</Box>
		</Container>
        <Footer />
		</>
	);
}

export { AdminPage };
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, Controller  } from "react-hook-form";
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

const classes = {
    formInput: {
        width: '100%',
    },
    formSubHeaders: {
        fontSize: 20,
        paddingBottom: 1
    },
    formSection: {
        paddingLeft: 2,
        paddingRight: 2,
        paddingBottom: 2,
        marginBottom: 2
    },
    selectInput: {
		width: '100%',
		marginTop: 2,
	},
    radioGroupStyle: {
        paddingTop: 5,
        paddingLeft: 15
    },
    datePickerStyle: {
        marginTop: 2,
    },
};

function ShippingInfo(){
    const loading = useSelector(state => state.load.loading);
    const loadDetails = useSelector(state => state.load.loadDetails);

    const { control, register, reset, formState: { errors, submitCount }, setValue } = useFormContext();

    useEffect(() => { 
		
    }, []);

    return (
        <>
            {!loading && loadDetails ?
            <>
            <Typography sx={classes.formSubHeaders}>Shipping Information</Typography>
            <Paper elevation={3} sx={classes.formSection}>
                <Grid container spacing={1} direction="row">
                    <Grid item xs={12} sm={6}>
                        <Grid container sx={{paddingRight: 1}} spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="id"
                                    control={control}
                                    defaultValue={loadDetails ? loadDetails.Id : ""}
                                    render={({ field }) => 
                                        <TextField 
                                            type="hidden"
                                            variant="outlined" 
                                            margin="normal" 
                                            sx={{display: 'none'}} 
                                            {...field} 
                                        />
                                    }
                                />
                                <Controller
                                    name="shipmentType"
                                    control={control}
                                    defaultValue={loadDetails ? loadDetails.ShipmentType : ""}
                                    render={({ field }) => 
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="shipmentTypeLabel" sx={{marginTop: 2}}>Type of Shipment</InputLabel>
                                            <Select labelId="shipmentTypeLabel" id="shipmentType" sx={classes.selectInput} {...field} error={errors.shipmentType ? true : false} label="Type of Shipment">
                                                <MenuItem value="tlRoad">TL Road</MenuItem>
                                                <MenuItem value="tlIntermodal">TL Intermodal</MenuItem>
                                                <MenuItem value="ltl">LTL</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.shipmentType?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="equipmentType"
                                    control={control}
                                    defaultValue={loadDetails ? loadDetails.EquipmentType : ""}
                                    render={({ field }) => 
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="equipmentTypeLabel" sx={{marginTop: 2}}>Equipment Type</InputLabel>
                                            <Select labelId="equipmentTypeLabel" id="equipmentType" sx={classes.selectInput} {...field} error={errors.equipmentType ? true : false} label="Equipment Type">
                                                <MenuItem value="dryVan">Dry Van</MenuItem>
                                                <MenuItem value="flatBed">Flat Bed</MenuItem>
                                                <MenuItem value="pupTruck">Pup Truck</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.equipmentType?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="firstPickupLocation"
                                    control={control}
                                    defaultValue={loadDetails ? loadDetails.FirstPickupLocation : ""}
                                    disabled
                                    render={({ field }) => 
                                        <TextField 
                                            label="First Pickup Location" 
                                            variant="outlined" 
                                            margin="normal" 
                                            size="small"
                                            sx={{width: '100%'}} 
                                            {...field} 
                                            error={errors.firstPickupLocation ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.firstPickupLocation?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    name="lastDeliveryLocation"
                                    control={control}
                                    defaultValue={loadDetails ? loadDetails.LastDeliveryLocation : ""}
                                    disabled
                                    render={({ field }) => 
                                        <TextField 
                                            label="Last Delivery Location" 
                                            variant="outlined" 
                                            margin="normal" 
                                            size="small"
                                            sx={{width: '100%'}} 
                                            {...field} 
                                            error={errors.lastDeliveryLocation ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.lastDeliveryLocation?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="generalComments"
                                    control={control}
                                    defaultValue={loadDetails ? loadDetails.GeneralComments : ""}
                                    render={({ field }) => 
                                        <TextField 
                                            label="General Comments (Optional)" 
                                            variant="outlined" 
                                            multiline
                                            rows={3}
                                            margin="normal" 
                                            sx={classes.formInput}
                                            {...field} 
                                            error={errors.generalComments ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.generalComments?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Grid container sx={{paddingRight: 1}} spacing={1}>
                            <Grid item xs={12}>
                                <Controller
                                    name="serviceLevel"
                                    control={control}
                                    defaultValue={loadDetails ? loadDetails.ServiceLevel : ""}
                                    render={({ field }) => 
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="serviceLevelLabel" sx={{marginTop: 2}}>Service Level</InputLabel>
                                            <Select labelId="serviceLevelLabel" id="serviceLevel" sx={classes.selectInput} {...field} error={errors.serviceLevel ? true : false} label="Service Level">
                                                <MenuItem value="regular">Regular</MenuItem>
                                                <MenuItem value="expedited">Expedited</MenuItem>
                                                <MenuItem value="team">Team</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.serviceLevel?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="scheduledPickupDate"
                                    control={control}
                                    defaultValue={loadDetails && loadDetails.ScheduledPickupDate ? dayjs(loadDetails.ScheduledPickupDate) : ''}
                                    disabled
                                    render={({ field }) => 
                                        <FormControl fullWidth sx={classes.datePickerStyle}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    slotProps={{
                                                        textField: {
                                                            error: false,
                                                            size: 'small'
                                                        },
                                                    }}
                                                    id="scheduledPickupDate"
                                                    {...field}
                                                    error={errors.scheduledPickupDate ? true : false}
                                                    label="Scheduled Pickup Date"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.scheduledPickupDate?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="scheduledDeliveryDate"
                                    control={control}
                                    defaultValue={loadDetails && loadDetails.ScheduledDeliveryDate ? dayjs(loadDetails.ScheduledDeliveryDate) : ''}
                                    disabled
                                    render={({ field }) => 
                                        <FormControl fullWidth sx={classes.datePickerStyle}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    slotProps={{
                                                        textField: {
                                                            error: false,
                                                            size: 'small'
                                                        },
                                                    }}
                                                    id="scheduledDeliveryDate"
                                                    {...field}
                                                    error={errors.scheduledDeliveryDate ? true : false}
                                                    label="Scheduled Delivery Date"
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.scheduledDeliveryDate?.message}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    name="trailerType"
                                    control={control}
                                    defaultValue={loadDetails ? loadDetails.TrailerType : ""}
                                    render={({ field }) => 
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="trailerTypeLabel" sx={{marginTop: 2}}>Trailer Type</InputLabel>
                                            <Select labelId="trailerTypeLabel" id="trailerType" sx={classes.selectInput} {...field} error={errors.trailerType ? true : false} label="Trailer Type">
                                                <MenuItem value="48Or 53Tandem">Tandem - 48' OR 53'</MenuItem>
                                                <MenuItem value="48Tandem">Tandem - 48'</MenuItem>
                                                <MenuItem value="53Tandem">Tandem - 53'</MenuItem>
                                                <MenuItem value="superB">Super B</MenuItem>
                                                <MenuItem value="48Tridem">Tridem - 48'</MenuItem>
                                                <MenuItem value="53Tridem">Tridem - 53'</MenuItem>
                                                <MenuItem value="48Or 53Tridem">Tridem - 48' OR 53'</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors.trailerType?.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Grid style={classes.radioGroupStyle}>
                            <FormControlLabel
                                label="Pallet Jack Required"
                                control={
                                    <Controller 
                                        name="palletJackRequired"
                                        control={control}
                                        defaultValue={loadDetails ? loadDetails.PalletJackRequired : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Power Tailgate Required"
                                control={
                                    <Controller 
                                        name="powerTailgateRequired"
                                        control={control}
                                        defaultValue={loadDetails ? loadDetails.PowerTailgateRequired : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Load Is Stackable"
                                control={
                                    <Controller 
                                        name="stackableLoad"
                                        control={control}
                                        defaultValue={loadDetails ? loadDetails.StackableLoad : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Flatbed to be Tarped"
                                control={
                                    <Controller 
                                        name="flatbedTarped"
                                        control={control}
                                        defaultValue={loadDetails ? loadDetails.FlatbedTarped : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Load Contains Hazardous Goods"
                                control={
                                    <Controller 
                                        name="hazardous"
                                        control={control}
                                        defaultValue={loadDetails ? loadDetails.Hazardous : false}
                                        render={({ field }) => 
                                            <Checkbox 
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            </>
            : null}
        </>
    );
}

export default ShippingInfo;
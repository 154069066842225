import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import DfqSampleLogo from '../../_resources/DFQ-Sample-Logo.png';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
    loginButton: {
		backgroundColor: '#308cdc',
		marginLeft: 2,
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15
    },
	signUpButton: {
		backgroundColor: '#20ac9c',
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15
    },
	buttonBox: {
		float: 'right',
		margin: 3
	},
	slogan: {
		fontWeight: 'bold',
		fontSize: 18
	}
};

function LandingPage() {
	const dispatch = useDispatch();

	// Reset login status
	useEffect(() => { 
		document.title = "DFQ";
		dispatch(userActions.logout()); 
	}, []);

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Box sx={classes.buttonBox}>
				<Link to='/signup'><Button variant="contained" sx={classes.signUpButton}>Sign Up</Button></Link>
				<Link to='/login'><Button variant="contained" sx={classes.loginButton}>Log In</Button></Link>
			</Box>
			<Grid
				container
				justify="flex-end"
				alignItems="center"
				sx={{ minHeight: '60vh'}}
			>
				<Grid item xs={12} sm={6} sx={{textAlign: "center"}}>
					<img src={DfqSampleLogo} height={120} alt='logo'/>
					<Typography sx={classes.slogan}>A simple and efficient quoting system<br />so you can focus on what you do best</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Typography variant='h2'>Sales Landing Page</Typography>
					<Typography variant='h2'>Design In Progress</Typography>
				</Grid>
			</Grid>
		</Container>
		<Footer />
		</>
	);
}

export { LandingPage };
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { numberWithCommas, addCapSpace } from '../../_helpers';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';

const classes = {
    formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
        "& .MuiInputBase-root.Mui-disabled": {
            "& > fieldset": {
                borderColor: 'blue',
            },
            "& .MuiInputBase-input": {
                WebkitTextFillColor: 'black', // Use this to change text color
                color: 'black', // Fallback for other browsers
                backgroundColor: '#F2F5F7',
            }
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: 'black', // Change this to the desired label color
        }
    },
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%', // Default width for smaller screens
        maxWidth: 600, // Maximum width for larger screens
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        maxHeight: '95vh', // Maximum height set to 95% of viewport height
        overflowY: 'auto', // Enable vertical scrolling if content overflows
        '@media (min-width: 600px)': { // Media query for larger screens
          width: 600,
        },
    },
    closeIcon: {
        float: 'right'
    },
	closeButton: {
		textTransform: 'none',
		left: '40%',
	},
};

function QuoteInfoModal(props){
    const { openQuoteInfoModal, handleCloseQuoteInfoModal, selectedQuote } = props;
    const dispatch = useDispatch();

    useEffect(() => { 

	}, []);

    const closeModal = () => {
		handleCloseQuoteInfoModal();
	};
    
    const closeIconClick = () => {
        handleCloseQuoteInfoModal();
    };

    return (
        <Modal open={openQuoteInfoModal} onClose={handleCloseQuoteInfoModal}>
            <Box sx={classes.modalStyle}>
                <IconButton onClick={() => closeIconClick()} sx={classes.closeIcon}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h4" sx={{textAlign:'center'}}>Quote Info</Typography>
                <Grid container spacing={1} sx={classes.formRow}>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.Id}
                            disabled
                            label="Quote No." 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={dayjs(selectedQuote.QuoteDate).format('MMM DD, YYYY')}
                            disabled
                            label="Quote Date" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.VendorName}
                            disabled
                            label="Vendor Name" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={classes.formRow}>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.QuoteCurrency}
                            disabled
                            label="Quote Currency" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.TotalPrice ? '$' + numberWithCommas(selectedQuote.TotalPrice) : selectedQuote.TotalPrice}
                            disabled
                            label="Total Price" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.TransitDays}
                            disabled
                            label="Transit Days" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={classes.formRow}>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.BestPrice ? '$' + numberWithCommas(selectedQuote.BestPrice) : selectedQuote.BestPrice}
                            disabled
                            label="Best Price" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.FSC ? '$' + numberWithCommas(selectedQuote.FSC) : selectedQuote.FSC}
                            disabled
                            label="FSC" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.DropFee ? '$' + numberWithCommas(selectedQuote.DropFee) : selectedQuote.DropFee}
                            disabled
                            label="Drop Fee" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={classes.formRow}>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={dayjs(selectedQuote.DateAvailable).format('MMM DD, YYYY')}
                            disabled
                            label="Date Available" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.EquipmentType ? addCapSpace(selectedQuote.EquipmentType) : selectedQuote.EquipmentType}
                            disabled
                            label="Equipment Type" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField 
                            defaultValue={selectedQuote.TrailerType ? addCapSpace(selectedQuote.TrailerType) : selectedQuote.TrailerType}
                            disabled
                            label="Trailer Type" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={classes.formRow}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            defaultValue={selectedQuote.LastEdited ? dayjs(selectedQuote.LastEdited).format('MMM DD, YYYY h:mm A') : 'N/A'}
                            disabled
                            label="Last Edited" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={classes.formRow}>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            defaultValue={selectedQuote.Comments ? selectedQuote.Comments : " "}
                            disabled
                            label="Comments" 
                            variant="outlined" 
                            margin="normal" 
                            size="small"
                            multiline
                            rows={4}
                            sx={classes.formInput}
                        />
                    </Grid>
                </Grid>
                <div style={{marginTop: 25}}>
                    <Button
                        variant="outlined"
                        sx={classes.closeButton}
                        startIcon={<CloseIcon />}
                        onClick={() => closeModal()}
                    > 
                        Close
                    </Button>
                </div>
            </Box>
        </Modal>
    );
}

export default QuoteInfoModal;
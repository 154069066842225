import { loadConstants } from '../_constants';
import { loadService } from '../_services';
import { alertActions } from './';
import dayjs from 'dayjs';

export const loadActions = {
    createLoad,
    getLoadTemplatesList,
    getLoadTemplateDetails,
    getPickupTemplatesList,
    getPickupTemplateDetails,
    getDeliveryTemplatesList,
    getDeliveryTemplateDetails,
    confirmLoadDetails,
    getCompanyLoads,
    getLoadDetails,
    editLoadDetails,
    deleteLoad,
    editPickupsDeliveries,
    addPickupToDelete,
    addDeliveryToDelete,
    clearPickupsDeliveriesToDelete
};

function createLoad(loadInfo) {
    return dispatch => {
        dispatch(request());
        loadService.createLoad(loadInfo)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Load was created!'));
                        window.history.pushState({}, undefined, `/loads/current`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.CREATE_LOAD_REQUEST } }
    function success() { return { type: loadConstants.CREATE_LOAD_SUCCESS } }
    function failure() { return { type: loadConstants.CREATE_LOAD_FAILURE } }
}

function getLoadTemplatesList(companyId) {
    return dispatch => {
        dispatch(request());
        loadService.getLoadTemplatesList(companyId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_LOAD_TEMPLATES_LIST_REQUEST } }
    function success(loadTemplatesList) { return { type: loadConstants.GET_LOAD_TEMPLATES_LIST_SUCCESS, loadTemplatesList } }
    function failure() { return { type: loadConstants.GET_LOAD_TEMPLATES_LIST_FAILURE } }
}

function getLoadTemplateDetails(loadId) {
    return dispatch => {
        dispatch(request());
        loadService.getLoadTemplateDetails(loadId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Load template found!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_LOAD_TEMPLATE_DETAILS_REQUEST } }
    function success(loadTemplateDetails) { return { type: loadConstants.GET_LOAD_TEMPLATE_DETAILS_SUCCESS, loadTemplateDetails } }
    function failure() { return { type: loadConstants.GET_LOAD_TEMPLATE_DETAILS_FAILURE } }
}

function getPickupTemplatesList(companyId) {
    return dispatch => {
        dispatch(request());
        loadService.getPickupTemplatesList(companyId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        //dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_PICKUP_TEMPLATES_LIST_REQUEST } }
    function success(pickupTemplatesList) { return { type: loadConstants.GET_PICKUP_TEMPLATES_LIST_SUCCESS, pickupTemplatesList } }
    function failure() { return { type: loadConstants.GET_PICKUP_TEMPLATES_LIST_FAILURE } }
}

function getPickupTemplateDetails(pickupId) {
    return dispatch => {
        dispatch(request());
        loadService.getPickupTemplateDetails(pickupId)
            .then(
                async response => {
                    if(response.status === 200){
                        // Will get an error if we don't convert the date to a dayjs object
                        response.data.Result.pickupDate = dayjs(response.data.Result.pickupDate);
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Pickup template found!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_PICKUP_TEMPLATE_DETAILS_REQUEST } }
    function success(pickupTemplateDetails) { return { type: loadConstants.GET_PICKUP_TEMPLATE_DETAILS_SUCCESS, pickupTemplateDetails } }
    function failure() { return { type: loadConstants.GET_PICKUP_TEMPLATE_DETAILS_FAILURE } }
}

function getDeliveryTemplatesList(companyId) {
    return dispatch => {
        dispatch(request());
        loadService.getDeliveryTemplatesList(companyId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        //dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_DELIVERY_TEMPLATES_LIST_REQUEST } }
    function success(deliveryTemplatesList) { return { type: loadConstants.GET_DELIVERY_TEMPLATES_LIST_SUCCESS, deliveryTemplatesList } }
    function failure() { return { type: loadConstants.GET_DELIVERY_TEMPLATES_LIST_FAILURE } }
}

function getDeliveryTemplateDetails(deliveryId) {
    return dispatch => {
        dispatch(request());
        loadService.getDeliveryTemplateDetails(deliveryId)
            .then(
                async response => {
                    if(response.status === 200){
                        // Will get an error if we don't convert the date to a dayjs object
                        response.data.Result.deliveryDate = dayjs(response.data.Result.deliveryDate);
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Delivery template found!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_DELIVERY_TEMPLATE_DETAILS_REQUEST } }
    function success(deliveryTemplateDetails) { return { type: loadConstants.GET_DELIVERY_TEMPLATE_DETAILS_SUCCESS, deliveryTemplateDetails } }
    function failure() { return { type: loadConstants.GET_DELIVERY_TEMPLATE_DETAILS_FAILURE } }
}

function confirmLoadDetails(loadInfo, pickups, deliveries) {
    var loadTemplateDetails = loadInfo;
    loadTemplateDetails.PickupList = pickups;
    loadTemplateDetails.DeliveryList = deliveries;
    return { type: loadConstants.GET_LOAD_TEMPLATE_DETAILS_SUCCESS, loadTemplateDetails }
}

function getCompanyLoads(companyId, dateRange) {
    return dispatch => {
        dispatch(request());
        loadService.getCompanyLoads(companyId, dateRange)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Found loads!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_COMPANY_LOADS_REQUEST } }
    function success(companyLoads) { return { type: loadConstants.GET_COMPANY_LOADS_SUCCESS, companyLoads } }
    function failure() { return { type: loadConstants.GET_COMPANY_LOADS_FAILURE } }
}

function getLoadDetails(loadId) {
    return dispatch => {
        dispatch(request());
        loadService.getLoadDetails(loadId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Found load details!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_LOAD_DETAILS_REQUEST } }
    function success(loadDetails) { return { type: loadConstants.GET_LOAD_DETAILS_SUCCESS, loadDetails } }
    function failure() { return { type: loadConstants.GET_LOAD_DETAILS_FAILURE } }
}

function editLoadDetails(loadInfo) {
    return dispatch => {
        dispatch(request());
        loadService.editLoadDetails(loadInfo)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Load details edited successfully!'));
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.EDIT_LOAD_DETAILS_REQUEST } }
    function success() { return { type: loadConstants.EDIT_LOAD_DETAILS_SUCCESS } }
    function failure() { return { type: loadConstants.EDIT_LOAD_DETAILS_FAILURE } }
}

function deleteLoad(loadId, deletedBy) {
    return dispatch => {
        dispatch(request());
        loadService.deleteLoad(loadId, deletedBy)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Load deleted!'));
                        window.history.pushState({}, undefined, `/loads/current`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.DELETE_LOAD_REQUEST } }
    function success() { return { type: loadConstants.DELETE_LOAD_SUCCESS } }
    function failure() { return { type: loadConstants.DELETE_LOAD_FAILURE } }
}

function editPickupsDeliveries(pickups, deliveries, pickupsToDelete, deliveriesToDelete, loadId, modifiedBy) {
    return dispatch => {
        dispatch(request());
        loadService.editPickupsDeliveries(pickups, deliveries, pickupsToDelete, deliveriesToDelete, loadId, modifiedBy)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Pickup/Deliveries edited!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.EDIT_PICKUPS_AND_DELIVERIES_REQUEST } }
    function success() { return { type: loadConstants.EDIT_PICKUPS_AND_DELIVERIES_SUCCESS } }
    function failure() { return { type: loadConstants.EDIT_PICKUPS_AND_DELIVERIES_FAILURE } }
}

function addPickupToDelete(pickupToDelete) {
    return { type: loadConstants.ADD_PICKUP_TO_DELETE, pickupToDelete }
}

function addDeliveryToDelete(deliveryToDelete) {
    return { type: loadConstants.ADD_DELIVERY_TO_DELETE, deliveryToDelete }
}

function clearPickupsDeliveriesToDelete() {
    return { type: loadConstants.CLEAR_PICKUPS_DELIVERIES_TO_DELETE }
}
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../../components/Header';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SampleTruckImage from '../../_resources/welcome-page-sample-truck.png';
import SamplePeopleImage from '../../_resources/welcome-page-sample-people.png';
import SampleWritingImage from '../../_resources/welcome-page-sample-writing.png';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden',
		paddingBottom: '15%'
	},
	featureBox : {
		marginTop: '15%',
		marginLeft: '10%'
	},
	dataBox : {
		marginTop: '15%',
		marginLeft: '20%',
	},
	featureTitle: {
		fontWeight: 'bold',
		fontSize: 30,
	},
	featureDescription: {

	},
	dataTitle: {
		fontWeight: 'bold',
		fontSize: 20,
	},
	dataCircle1: {
		fontSize: '5rem',
		width: '10rem',

		backgroundColor: '#d5e7f7',
		color: '#4b5c6b',
		padding: '1rem',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		marginLeft: '10%',
		marginTop: '3%'
	},
	dataCircle2: {
		fontSize: '5rem',
		width: '10rem',

		backgroundColor: '#d1efec',
		color: '#788896',
		padding: '1rem',
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%',
		marginLeft: '10%',
		marginTop: '3%'
	}
};

function WelcomePage() {
	const dispatch = useDispatch();
	const user = useSelector(state => state.authentication.user);
    const welcomeSummary = useSelector(state => state.authentication.welcomeSummary);
	const loading = useSelector(state => state.authentication.loading);

	useEffect(() => { 
		document.title = "DFQ - Welcome";
        dispatch(userActions.getWelcomeSummary());
		console.log(user);
    }, []);

	return (
		<>
		<CssBaseline />
		<Header />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<Box sx={classes.featureBox}>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<img src={SampleTruckImage} height={120} alt='truck'/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography sx={classes.featureTitle}>Loads</Typography>
								<Typography sx={classes.featureDescription}>Review current and past loads in an efficient manner so you can find the best price and service to fit your unique business needs</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				{!loading ?
				<Grid item xs={12} sm={6}>
					<Box sx={classes.dataBox}>
						<Typography sx={classes.dataTitle}>Loads that need to be awarded</Typography> 
						<Typography sx={classes.dataCircle1}>{welcomeSummary.LoadsToBeAwarded}</Typography>
					</Box>
				</Grid>
				: null}
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<Box sx={classes.featureBox}>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<img src={SamplePeopleImage} height={120} alt='truck'/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography sx={classes.featureTitle}>Lorem</Typography>
								<Typography sx={classes.featureDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				{!loading ?
				<Grid item xs={12} sm={6}>
					<Box sx={classes.dataBox}>
						<Typography sx={classes.dataTitle}>New quotes received</Typography> 
						<Typography sx={classes.dataCircle2}>{welcomeSummary.NewQuotes}</Typography>
					</Box>
				</Grid>
				: null}
			</Grid>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<Box sx={classes.featureBox}>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<img src={SampleWritingImage} height={120} alt='truck'/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Typography sx={classes.featureTitle}>Ipsum</Typography>
								<Typography sx={classes.featureDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				{!loading ?
				<Grid item xs={12} sm={6}>
					<Box sx={classes.dataBox}>
						<Typography sx={classes.dataTitle}>Next Survivor Season</Typography> 
						<Typography sx={classes.dataCircle1}>46</Typography>
					</Box>
				</Grid>
				: null}
			</Grid>
		</Container>
		<Footer />
		</>
	);
}

export { WelcomePage };